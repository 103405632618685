import * as dateFns from 'date-fns'


const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]';
const lowerKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((o) => lowerKeys(o));
  }
  return Object.keys(obj).reduce((prev, curr) => {
    prev[curr.toLowerCase()] = lowerKeys(obj[curr]);
    return prev;
  }, {});
};
const nth = (number) => {
  return number > 0
    ? ["th", "st", "nd", "rd"][
        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
      ]
    : "";
};


// place functions independent of project here
export default {
  truncate: (str, max) => {
    if (str) {
      return str.length > max ? `${str.substr(0, max - 1)}...` : str;
    }
    return '';
  },
  timestamp: () => Math.round(new Date().getTime() / 1000),
  // formatTimestamp: (ts) => {
  //   const m = moment.unix(ts);
  //   return m.format('MMMM Do YYYY, h:mm:ss a');
  // },
  round: (value, decimals) => {
    decimals = typeof decimals !== 'undefined' ? decimals : 15;
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  isset: (obj) => (typeof obj !== 'undefined'),
  isEmail: (email) => {
    //const regex = /^(([^<>()\[]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
    //return true;
  },
  getCookie: (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
  },
  isEmpty: (obj) => Object.keys(obj).length === 0,
  sleep: (ms) => new Promise((res) => setTimeout(res, ms)),
  formatNumCommas: (x) => {
    if (!x) { return '0'; }
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    const n = parseFloat(x);

    // Check if the number has a decimal part
    if (n % 1 !== 0) {
        // If it has a decimal part, format with 2 decimal places
        return n.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        // If it is a whole number, format without decimal places
        return n.toLocaleString('en-US');
    }
  },
  formatAmount: (amount) => {
    if (amount === null || amount === undefined) {
      return '-';
    }
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
  },
  formatCurrency: (x) => {
    if (!x) { return '$'; }
    // return "S$ " + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    const n = parseFloat(x);
    return `$${n.toLocaleString('en-US', { maximumFractionDigits: 2 })}`;
  },
  formatShortHandNumber: (value) => {
    if (value >= 1000000) { value = `${value / 1000000}m`; } else if (value >= 1000) { value = `${value / 1000}k`; }
    return value;
  },
  formatTitleCase: (str) => {
    if (!str) return '';
    return str.toLowerCase().split(' ').map((word) => {
      if (word) { return word.replace(word[0], word[0].toUpperCase()); }
      return '';
    }).join(' ');
  },
  formatDateDetail: (str) => {
    var d = new Date(parseInt(str) * 1);
        //13/2/2022, 2:38:45 AM 
    const day = d.toLocaleString('default', { day: '2-digit' });
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: '2-digit' });
    const t = d.toLocaleString('default', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${day} ${month} ${year}, ${t}`;
  },
  formatDateShort: (str) => {
    var d = new Date(parseInt(str) * 1);
    const day = d.toLocaleString('default', { day: '2-digit' });
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.toLocaleString('default', { year: 'numeric' });    
    return `${day} ${month} ${year}`;
  },  
  formatDateFull: (str, sup=true) => {
    var d = new Date(parseInt(str) * 1);
    let n = d.toLocaleString('default', { day: 'numeric' })
    let n2 = nth(n);
    if (sup) {
      n2 = "<sup>" + n2 + "</sup>";
    }
    const day = n + n2;
    const month = d.toLocaleString('default', { month: 'long' });
    const year = d.toLocaleString('default', { year: 'numeric' });    
    return `${day} ${month} ${year}`;
  },   
  formatTimeFromNow: (isoStr) => {
    if (isoStr) {
      const d = dateFns.parseISO(isoStr);
      return dateFns.formatDistanceToNow(d);
    }
    return "";
  },
  formatDateShortReadable: (str) => {
    if (str) {
      return dateFns.format(new Date(str), "do MMM yyyy");
    }
    return "";
  },
  formatDateDetailReadable: (str) => {
    if (str) {
      return dateFns.format(new Date(str), "do MMM yyyy HH:MM a");
    }
    return "";
  },
  formatDateTimeReadable: (str) => {
    return dateFns.format(new Date(str), "HH:mm a");
  },      
  nth: nth,
  isArray: (obj) => Object.prototype.toString.call(obj) === '[object Array]',
  last: (arr) => arr[arr.length - 1],
  recursivelyLowercaseJSONKeys: (obj) => lowerKeys(obj),
  isMobile: () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  },
  getParameterByName:(name, url) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },  
  colorLuminance: (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i*2,2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00"+c).substr(c.length);
    }

    return rgb;
  },
  formatPath: (path) => {
    return path.split("/").filter(Boolean).join("/");
  },
  fileUrlToBlob: async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return blob;
  },
  joinStr: (...str) => {
    return str.filter((s) => s).join(' ');
  },
  getRemainingTimeFromNow: (timestamp) => {
    // Current time in milliseconds
    const now = Date.now();

    // Calculate the difference in milliseconds directly
    const diff = Math.abs(timestamp - now);

    // Calculate hours, minutes, and seconds
    const d = {
      hours: Math.floor(diff / (1000 * 60 * 60)),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      seconds: Math.floor((diff / 1000) % 60)
    };
    return `${d.hours}h ${d.minutes}m`
  },
  numberOnlyInput: (event) => {
    // Get the pressed key code
    const keyCode = event.which || event.keyCode;

    // if("0123456789".split("").indexOf(event.key) == -1) event.preventDefault()
    // Allow numbers 0-9 and specific keyboard control keys (e.g., backspace, delete, arrow keys)
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) || // 0-9
        (keyCode >= 96 && keyCode <= 105) || // Numeric keypad 0-9
        keyCode === 8 || // Backspace
        keyCode === 9 || // Tab
        keyCode === 13 || // Enter
        keyCode === 27 || // Escape
        (keyCode >= 37 && keyCode <= 40) || // Arrow keys
        keyCode === 46
      )
    ) {
      // Delete key
      event.preventDefault();
    }
  }
  
};
