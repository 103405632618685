import { defineStore } from "pinia";
import * as formService from "@/services/service.form";
import _ from "lodash";
import { customJoi, getJoiData, prepareJoiErrorArr } from "@/models/customJoi";
import * as FormModel from "@/models/model.form";

const obj = {
  //###################
  formDigitalFinanceApplication: {
    formName: "digital-finance-application",
    //formId: null,
    id: null,
    userId: null,
    formStatus: "draft",

    //step 1
    formData: {
      step1: {
        typeOfServices: [],
        companyName: "",
        companyTradingName: "",
        companyRegNo: "",
        companyWebsite: "",
        companyEntityType: "",
        companyEntityOtherType: "",
        addressDiffAsAcraFlag: false,
        companyAddress1: "",
        companyAddress2: "",
        companyPostalCode: "",
        companyState: "",
        companyCity: "",
        companyCountry: "",
        companyMainIndustry: "",
        companySubIndustry: "",

        //add
        contactPersonName: "",
        contactPersonMobileCountryCode: "60",
        contactPersonMobile: "",
        contactPersonEmail: "",
        contactPersonDesignation: "",

        businessHours: "",
      },
      step2: {
        businessDescription: "",
        expectedTurnover: 0,
        sourceOfFund: "",
        countryOperations: [],
        tradingCurrencies: [],
        relevantPurposes: [],
        expectedTransactionAmt: 0,

        //add
        numberOfEmployees: 0,
        currentMonthlySale: 0,
        estimatedAverageBillingAmount: 0,
        shippingFulfillmentPeriod: 0,

        eCommerceUsed: false,
        eCommercePlatformName: "",
        currentPaymentGateway: false,
        //true
        serviceProvider: "",
        leaveCurrentPaymentGatewayReason: "",
        sixMonthsChargeBackRatio: 0,
        acceptCreditCard: false,

        settlementPeriod: "",
        settlementMinAmount: 0,

        officeAreaZoned: "",
        officeSpace: "",

        bankName: "",
        bankAccountName: "",
        branchLocation: "",
        beneficiaryName: "",
        swiftCode: "",
      },
      step3: {
        personnels: [],
      },
      step4: {
        filesAcra: [],
        filesMemorandum: [],
        filesId: [],
        filesCoProfile: [],

        //add
        filesBankStatement: [],
        filesCheckoutFlow: [],
        filesOfficePicture: [],
      },
    },

    //error flags
    step1Status: "empty", //empty, error, completed
    step2Status: "empty",
    step3Status: "empty",
    step4Status: "empty",
  },

  //###################
  formCorpsecRegNewCo: {
    formName: "corpsec-register-new-company",
    //formId: null,
    id: null,
    userId: null,
    formStatus: "draft",

    //step 1
    formData: {
      step1: {
        companyName1: "",
        companyName2: "",
        companyName3: "",
      },
      step2: {
        localDirectorFlag: true,
      },
      step3: {
        noOfShareholder: "",
        corporateSharholderFlag: false,
      },
      step4: {
        useOurAddressFlag: true,
      },
      step5: {
        useAccountingFlag: true,
      },
      step6: {
        useEmploymentVisaSupportFlag: false,
        noOfEp: 0,
        noOfLoc: 0,
      },
    },

    //error flags
    step1Status: "empty", //empty, error, completed
    step2Status: "empty",
    step3Status: "empty",
    step4Status: "empty",
    step5Status: "empty",
    step6Status: "empty",
  },

  //###################
  formCorpsecTransferInCo: {
    formName: "corpsec-transfer-in-company",
    //formId: null,
    id: null,
    userId: null,
    formStatus: "draft",

    //step 1
    formData: {
      step1: {
        companyName: "",
        companyRegNo: "",
        contactName: "",
        contactEmail: "",
        contactMobile: "",
        hearFrom: "",
      },
      step2: {
        existingCorpsecFlag: false,
        currentCorpsecName: "",
        currentCorpsecContactNo: "",
        currentCorpsecEmail: "",
        transferReason: "",
      },
      step3: {
        filesAcra: [],
        filesMemorandum: [],
        filesId: [],
      },
    },

    //error flags
    step1Status: "empty", //empty, error, completed
    step2Status: "empty",
    step3Status: "empty",
  },

  //###################
  formCorpsecRegNewCoCombine: {
    formName: "corpsec-register-new-company-combine",
    //formId: null,
    id: null,
    userId: null,
    formStatus: "draft",

    //step 1
    formData: {
      step1: {
        companyName1: "",
        companyName2: "",
        companyName3: "",
        capitalBaseCurrency: "",
        capitalAmount: 0,
        companyActivity1: "",
        companyActivity2: "",
        regAddressDiffFlag: true,
        regAddress1: "",
        regAddress2: "",
        regAddressPostalCode: "",
      },
      step2: {
        members: [],
        filesId: [],
      },
      step3: {
        directors: [
          // {
          //     "directorMobileCountryCode": 93,
          //     "directorAddress1": "address1",
          //     "directorIdNum": "9090909090",
          //     "directorPostalCode": "123123",
          //     "directorFullName": "test",
          //     "directorEmail": "joel.oye@meyzer360.com",
          //     "directorOtherPosition": "ceo",
          //     "directorMobile": 12312312,
          //     "directorDob": "2024-05-01T06:02:00.000Z",
          //     "directorNationality": "Singapore",
          //     "altAddressFlag": false
          // }
        ],
        confirmDirectorNotStruckOffFlag: false,
        filesId: [],
        useOurCorpSecAgreeFlag: false,
        otherDirectorships: "",
      },
      step4: {
        companyConstituionAgreeFlag: false,
      },
      step5: {
        fye: "",
      },
      step6: {
        applicantName: "",
        applicantIdNo: "",
        applicantEmail: "",
        applicantDeclareFlag: false,
      },
    },

    //error flags
    step1Status: "empty", //empty, error, completed
    step2Status: "empty",
    step3Status: "empty",
    step4Status: "empty",
    step5Status: "empty",
    step6Status: "empty",
  },

  //###################
  formCorpsecRegNewCoCombineMY: {
    formName: "corpsec-register-new-company-combine-my",
    //formId: null,
    id: null,
    userId: null,
    formStatus: "draft",

    //step 1
    formData: {
      step1: {
        companyName1: "",
        companyName2: "",
        companyName3: "",
        capitalBaseCurrency: "",
        capitalAmount: 0,
        companyActivity1: "",
        companyActivity2: "",
        regAddress1: "",
        regAddress2: "",
        regAddressPostalCode: "",
        fye: "",
      },
      step2: {
        members: [],
        filesId: [],
        memberConsentFlag: false,
      },
      step3: {
        directors: [],
        filesId: [],

        contactPersonName: "",
        contactPersonCorrespondence: "",
        // contactPersonAddress1: "",
        // contactPersonAddress2: "",
        // contactPersonPostalCode: "",
        contactPersonEmail: "",
        contactPersonMobileCountryCode: "60",
        contactPersonMobile: "",
      },
      step4: {
        extraService: "",
      },
      step5: {
        personalDataPrivacyFlag: false,
      },
      // step5: {
      //   fye: "",
      // },
      // step6: {
      //   applicantName: "",
      //   applicantIdNo: "",
      //   applicantEmail: "",
      //   applicantDeclareFlag: false,
      // },
    },

    //error flags
    step1Status: "empty", //empty, error, completed
    step2Status: "empty",
    step3Status: "empty",
    step4Status: "empty",
    step5Status: "empty",
    // step6Status: "empty",
  },

  formCentralContact: {
    formName: "central-contact",
    id: null,
    userId: null,
    formStatus: "draft",

    formData: {
      name: "",
      email: "",
      phoneCountryCode: "65",
      phone: "",
      subject: "",
      message: "",
    },
  },

  formCorpsecGetStarted: {
    formName: "corpsec-register-get-started",
    id: null,
    userId: null,
    formStatus: "draft",
    formData: {
      serviceType: null,
      employmentType: null,
      shareholderNum: null,
      registeredAddress: null,
      accountingPackage: null,
      bankAccount: null,
      nomineeDirector: null,
      visaPackage: null,
      epNum: 0,
      locNum: 0,
    },
  },

  formError: {},
};

export const useStoreForm = defineStore("form", {
  state: () => _.cloneDeep(obj),
  getters: {
    // getKeyValue(key){
    //     return state[key]
    // }
  },
  actions: {
    reset() {
      //Object.assign(this, _.cloneDeep(obj));
      this.$patch(obj);
    },
    getById(formId) {
      return formService.getById(formId);
    },
    getPublicById(formId) {
      return formService.getPublicById(formId);
    },
    async debugPreload() {
      const o = await formService.getById("01HF3PXTNTZC72XKAW5BT6F973");
      delete o.data.id;
      delete o.data.userId;
      delete o.data.formStatus;
      delete o.data.createdBy;
      delete o.data.updatedBy;
      o.data.formData.step1.companyName = "Loytest Co " + Math.random().toString(36).slice(2, 7);
      this.$patch({ formDigitalFinanceApplication: o.data });
    },
    withdrawById(formId) {
      return formService.withdrawById(formId);
    },

    //############## digital-finance-application ##############

    getDigitalFinanceApplications() {
      return formService.getDigitalFinanceApplications();
    },
    checkIfFormDigitalFinanceApplicationHasError() {
      const j1 = getJoiData(FormModel.DigitalFinanceApplicationSchema.step1, this.formDigitalFinanceApplication.formData.step1);
      const j2 = getJoiData(FormModel.DigitalFinanceApplicationSchema.step2, this.formDigitalFinanceApplication.formData.step2);
      const j3 = getJoiData(FormModel.DigitalFinanceApplicationSchema.step3, this.formDigitalFinanceApplication.formData.step3);
      const j4 = getJoiData(FormModel.DigitalFinanceApplicationSchema.step4, this.formDigitalFinanceApplication.formData.step4);

      // console.log("j1", j1.error)
      // console.log("j2", j2.error)
      // console.log("j3", j3.error)
      // console.log("j4", j4.error)

      return j1.error || j2.error || j3.error || j4.error;
    },
    submitDigitalFinanceApplication(formId, isDraft = false) {
      const d = this.formDigitalFinanceApplication.formData;
      // console.log('test', d)

      // Overwrite to string
      // console.log(d.step2)
      d.step2.expectedTurnover = d.step2.expectedTurnover.toString();
      d.step2.expectedTransactionAmt = d.step2.expectedTransactionAmt.toString();
      const data = { formId: formId, formData: d };

      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitDigitalFinanceApplicationDraft(data);
      } else {
        o = formService.submitDigitalFinanceApplication(data);
      }
      return o;
    },
    async saveDigitalFinanceApplicationResumeEmail(email) {
      const d = this.formDigitalFinanceApplication.formData;
      const data = { formData: d, email: email };
      const o = await formService.saveDigitalFinanceApplicationResumeEmail(data);
      //this.formDigitalFinanceApplication.id = o.data.formId;
      //console.log("oo", o)
      return o;
    },
    submitDigitalFinanceApplicationPublic(formId, isDraft = false) {
      const d = this.formDigitalFinanceApplication.formData;

      // Overwrite to string
      // d.step2.contactPersonMobileCountryCode = d.step2.contactPersonMobileCountryCode.toString();

      const data = { formId: formId, formData: d };
      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitDigitalFinanceApplicationPublicDraft(data);
      } else {
        o = formService.submitDigitalFinanceApplicationPublic(data);
      }

      return o;
    },

    //############## corpsec-reg-new-co ##############

    getCorpsecRegNewCos() {
      return formService.getCorpsecRegNewCos();
    },
    checkIfFormCorpsecRegNewCoHasError() {
      const j1 = getJoiData(FormModel.CorpsecRegNewCoSchema.step1, this.formCorpsecRegNewCo.formData.step1);
      const j3 = getJoiData(FormModel.CorpsecRegNewCoSchema.step3, this.formCorpsecRegNewCo.formData.step3);
      // console.log("j1", j1.error)
      return j1.error || j3.error;
    },
    submitCorpsecRegNewCo(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCo.formData;
      const data = { formId: formId, formData: d };
      console.log("CorpsecRegNewCo", data);
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCo(data);
      }
      return o;
    },
    async saveCorpsecRegNewCoResumeEmail(email) {
      const d = this.formCorpsecRegNewCo.formData;
      const data = { formData: d, email: email };
      const o = await formService.saveCorpsecRegNewCoResumeEmail(data);
      //this.formDigitalFinanceApplication.id = o.data.formId;
      console.log("oo", o);
      return o;
    },
    submitCorpsecRegNewCoPublic(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCo.formData;
      const data = { formId: formId, formData: d };
      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoPublicDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCoPublic(data);
      }

      return o;
    },

    //############## corpsec-transfer-in-co ##############
    getCorpsecTransferInCos() {
      return formService.getCorpsecTransferInCos();
    },
    checkIfFormCorpsecTransferInCoHasError() {
      const j1 = getJoiData(FormModel.CorpsecTransferInCoSchema.step1, this.formCorpsecTransferInCo.formData.step1);
      const j2 = getJoiData(FormModel.CorpsecTransferInCoSchema.step2, this.formCorpsecTransferInCo.formData.step2);
      const j3 = getJoiData(FormModel.CorpsecTransferInCoSchema.step3, this.formCorpsecTransferInCo.formData.step3);
      // console.log("j1", j1.error)
      return j1.error || j2.error || j3.error;
    },
    submitCorpsecTransferInCo(formId, isDraft = false) {
      const d = this.formCorpsecTransferInCo.formData;
      const data = { formId: formId, formData: d };
      console.log("CorpsecTransferInCo ", isDraft, data);
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecTransferInCoDraft(data);
      } else {
        o = formService.submitCorpsecTransferInCo(data);
      }
      return o;
    },
    async saveCorpsecTransferInCoResumeEmail(email) {
      const d = this.formCorpsecTransferInCo.formData;
      const data = { formData: d, email: email };
      const o = await formService.saveCorpsecTransferInCoResumeEmail(data);
      //this.formDigitalFinanceApplication.id = o.data.formId;
      console.log("oo", o);
      return o;
    },
    submitCorpsecTransferInCoPublic(formId, isDraft = false) {
      const d = this.formCorpsecTransferInCo.formData;
      const data = { formId: formId, formData: d };
      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecTransferInCoPublicDraft(data);
      } else {
        o = formService.submitCorpsecTransferInCoPublic(data);
      }

      return o;
    },

    //############## corpsec-reg-new-co-combine ##############

    getCorpsecRegNewCoCombines() {
      return formService.getCorpsecRegNewCoCombines();
    },
    checkIfFormCorpsecRegNewCoCombineHasError() {
      const j1 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step1, this.formCorpsecRegNewCoCombine.formData.step1);
      const j2 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step2, this.formCorpsecRegNewCoCombine.formData.step2);
      const j3 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step3, this.formCorpsecRegNewCoCombine.formData.step3);
      const j4 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step4, this.formCorpsecRegNewCoCombine.formData.step4);
      const j5 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step5, this.formCorpsecRegNewCoCombine.formData.step5);
      const j6 = getJoiData(FormModel.CorpsecRegNewCoCombineSchema.step6, this.formCorpsecRegNewCoCombine.formData.step6);

      return j1.error || j2.error || j3.error || j4.error || j5.error || j6.error;
    },

    submitCorpsecRegNewCoCombine(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCoCombine.formData;
      const data = { formId: formId, formData: d };
      console.log("CorpsecRegNewCoCombine", data);
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoCombineDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCoCombine(data);
      }
      return o;
    },
    async saveCorpsecRegNewCoCombineResumeEmail(email) {
      const d = this.formCorpsecRegNewCoCombine.formData;
      const data = { formData: d, email: email };
      const o = await formService.saveCorpsecRegNewCoCombineResumeEmail(data);
      //this.formDigitalFinanceApplication.id = o.data.formId;
      console.log("oo", o);
      return o;
    },
    submitCorpsecRegNewCoCombinePublic(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCoCombine.formData;
      const data = { formId: formId, formData: d };
      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoCombinePublicDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCoCombinePublic(data);
      }

      return o;
    },
    //############## central-contact ##############
    submitCentralContact(formData) {
      //const d = this.formCentralContact.formData;
      const data = { formId: null, formData: formData };
      console.log("CentralContact", data);
      let o = null;
      o = formService.submitCentralContact(data);
      return o;
    },

    //############## corpsec-reg-new-co-combine-malaysia ##############

    getCorpsecRegNewCoCombinesMY() {
      return formService.getCorpsecRegNewCoCombinesMY();
    },
    checkIfFormCorpsecRegNewCoCombineMYHasError() {
      const j1 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step1, this.formCorpsecRegNewCoCombineMY.formData.step1);
      const j2 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step2, this.formCorpsecRegNewCoCombineMY.formData.step2);
      const j3 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step3, this.formCorpsecRegNewCoCombineMY.formData.step3);
      const j4 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step4, this.formCorpsecRegNewCoCombineMY.formData.step4);
      const j5 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step5, this.formCorpsecRegNewCoCombineMY.formData.step5);
      // const j6 = getJoiData(FormModel.CorpsecRegNewCoCombineMYSchema.step6, this.formCorpsecRegNewCoCombineMY.formData.step6);

      return j1.error || j2.error || j3.error || j4.error || j5.error;
    },

    submitCorpsecRegNewCoCombineMY(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCoCombineMY.formData;
      const data = { formId: formId, formData: d };
      console.log("CorpsecRegNewCoCombine", data);
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoCombineMYDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCoCombineMY(data);
      }
      return o;
    },
    async saveCorpsecRegNewCoCombineMYResumeEmail(email) {
      const d = this.formCorpsecRegNewCoCombineMY.formData;
      const data = { formData: d, email: email };
      const o = await formService.saveCorpsecRegNewCoCombineMYResumeEmail(data);
      return o;
    },
    submitCorpsecRegNewCoCombineMYPublic(formId, isDraft = false) {
      const d = this.formCorpsecRegNewCoCombineMY.formData;
      const data = { formId: formId, formData: d };
      //console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null;
      if (isDraft) {
        o = formService.submitCorpsecRegNewCoCombineMYPublicDraft(data);
      } else {
        o = formService.submitCorpsecRegNewCoCombineMYPublic(data);
      }

      return o;
    },

    //############## get-started ##############

    submitCorpsecGetStarted(formId) {
      const d = this.formCorpsecGetStarted.formData;
      const data = { formId: formId, formData: d };
      const o = formService.submitCorpsecGetStarted(data);
      return o;
    },

    submitCallbackRequest(formData) {
      const o = formService.submitCallbackRequest(formData);
      return o;
    }
  },
});

/*


    submitDigitalFinanceApplication (isPublic = false) {
      const d = this.formDigitalFinanceApplication.formData;
      const data = {formName:"digital-finance-application", formData:d}
      console.log("submitDigitalFinanceApplication", isPublic, data)
      let o = null
      if (isPublic) {
        o = formService.submitDigitalFinanceApplicationPublic(data);
      } else {
        o = formService.submitDigitalFinanceApplication(data);
      }
      return o;
    },


    */
