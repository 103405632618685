import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import { useStoreSite } from "@/stores/store.site";
import { useStoreAccount } from "@/stores/store.account";
import Constant from "@/helpers/Constant";
import Cookies from "js-cookie";
import ShareFunc from "@/helpers/ShareFunc";
import { useGtm } from '@gtm-support/vue-gtm';
import Utils from "./helpers/Utils";

const routes = [
  /**/
  {
    path: "/",
    name: "index",
    redirect: "/authenticate",
  },
  {
    path: '/get-started',
    name: 'get-started',
    //redirect: "/get-started/service-type",
    component: () => import('@/views/get-started/GetStarted._Landing.vue'),
    meta: { layout: "none", requireAuth: false },
    props: route => {
      const url = new URL(window?.location?.href);
      const fd = url.searchParams.get('fd'); 
      let encoded = fd ||route.query.fd || null;
      let decodedData;
      if(encoded){
        try {
          decodedData = atob(encoded);
        } catch (error) {
          console.log('invalid code');
        }
      }
      const h = window?.location?.hash;
      let step =  h || route.hash || 'service-type'; // first step hash is service-type
      return {
        step: step?.slice(1), // remove '#' symbol
        formData: decodedData
      };
    },
    // children: [
    //   { path:'service-type', props: {step:"service-type"}, component:()=> import('@/views/get-started/GetStarted._Landing.vue') },
    //   { path:'employment-type', props: {step:"employment-type"}, component:()=> import('@/views/get-started/GetStarted._Landing.vue') },
    //   { path:'accounting-package', props: {step:"saccounting-package"}, component:()=> import('@/views/get-started/GetStarted._Landing.vue') },
    // ],
  },
  {
    path: '/get-started/payment/success',
    name: 'get-started-payment-success',
    component: () => import('@/views/get-started/GetStarted._PaymentSuccess.vue'),
    props: route => {
      const url = new URL(window?.location?.href);
      const fd = url.searchParams.get('fd'); 
      let encoded = fd?.split(' ').join('+') || route.query.fd || null;
      let decodedData;
      if(encoded){
        try {
          decodedData = atob(encoded);
        } catch (error) {
          console.log('invalid code');
        }
      }
      return {
        formData: decodedData
      };
    },
    meta: {layout: "none", requiredAuth: true},
  },
  // {
  //   path: '/get-started',
  //   name: 'get-started',
  //   component: () => import('@/views/get-started/GetStarted._Landing.vue'),
  //   meta: { layout: "blank", requireAuth: false },
  // },  
  {
    path: "/authenticate",
    name: "authenticate",
    component: () => import("@/views/Site.Spinner.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/auth-callback",
    name: "auth-callback",
    component: () => import("@/views/Auth.Callback.vue"),
    meta: { layout: "blank" },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { layout: "main", requireAuth: true },
  },
  {
    path: "/individual",
    name: "individual",
    component: () => import("@/views/Individual.vue"),
    meta: { layout: "main-small", requireAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requireAuth: true, layout: "main", breadcrumb: {label:"profile", to: "/profile"} },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
      },
      {
        path: "password",
        name: "password",
        component: () => import("@/views/Profile.Password.vue"),
      },
    ],
  },
  {
    path: "/access",
    name: "access",
    component: () => import("@/views/Access.vue"),
    meta: { layout: "main-small", requireAuth: true },
  },  
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Order.vue"),
    meta: { layout: "main-small", requireAuth: true },
  },  
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/Account.Notification.vue"),
    meta: { layout: "main", requireAuth: true },
  },  
  {
    path: "/sign-out",
    name: "sign-out",
    component: () => import("@/views/Site.SignOut.vue"),
    meta: { layout: "landing", requireAuth: true },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Site.Terms.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/Site.Privacy.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import('@/views/forms/_public/Contact.vue'),
    meta: { breadcrumb: {label:"Contact", to: "/contact"} },
  },        
  {
    path: "/company-account-invitation",
    name: "verify-company-account-invitation",
    component: () => import("@/views/Verify.CompanyAccountInvitation.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/purchase-module-invitation",
    name: "verify-purchase-module-invitation",
    component: () => import("@/views/Verify.PurchaseModuleInvitation.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/apply-digital-finance",
    name: "apply-digital-finance",
    meta: { layout: "blank", requireAuth: false },
    component: () => import('@/views/forms/_public/DigitalFinanceApplication.vue'),
    //redirect: { path: 'forms/public/digital-finance-application' }
  },
  {
    path: "/register-new-company-combine",
    name: "register-new-company-combine",
    meta: { layout: "blank", requireAuth: false },
    component: () => import('@/views/forms/_public/CorpsecRegNewCoCombine.vue'),
    //redirect: { path: 'forms/public/digital-finance-application' }
  },  
  {
    path: "/register-new-company",
    name: "register-new-company",
    meta: { layout: "blank", requireAuth: false },
    component: () => import('@/views/forms/_public/CorpsecRegNewCoCombine.vue'),
    //component: () => import('@/views/forms/_public/CorpsecRegNewCo.vue'),
    //redirect: { path: 'forms/public/digital-finance-application' }
  },
  {
    path: "/register-new-company-my",
    name: "register-new-company-my",
    meta: { layout: "blank", requireAuth: false },
    component: () => import('@/views/forms/_public/CorpsecRegNewCoCombineMy.vue'),
    //component: () => import('@/views/forms/_public/CorpsecRegNewCo.vue'),
    //redirect: { path: 'forms/public/digital-finance-application' }
  },
  {
    path: "/transfer-company-secretary",
    name: "transfer-company-secretary",
    meta: { layout: "blank", requireAuth: false },
    component: () => import('@/views/forms/_public/CorpsecTransferInCo.vue'),
    //redirect: { path: 'forms/public/digital-finance-application' }
  },    
  {
    path: '/forms',
    name: 'forms',
    meta: { layout: "blank" },
    children: [

      //############## digital-finance-application ############## 
      {
        path: "public/digital-finance-application",
        name: "public-digital-finance-application",
        props: {isPublic:true},
        component: () => import('@/views/forms/Form.DigitalFinanceApplication/Form.DigitalFinanceApplication.vue'),
        meta: { layout: "blank-locale", breadcrumb: {label:"Digital Finance Application", to: "/forms/digital-finance-application"} },
      },
      {
        path: "public/digital-finance-application/resume/:id",
        name: "digital-finance-application-resume",
        props: {isPublic:true, isPublicSaved:true},
        component: () => import('@/views/forms/Form.DigitalFinanceApplication/Form.DigitalFinanceApplication.vue'),
        meta: { layout: "blank-locale", breadcrumb: {label:"Digital Finance Application", to: "/forms/public/digital-finance-application/resume/:id"} },
      },      
      {
        path: "digital-finance-application",
        name: "digital-finance-application",
        component: () => import('@/views/forms/Form.DigitalFinanceApplication/Form.DigitalFinanceApplication.vue'),
        meta: { requireAuth: true, layout: "blank-logged-in", breadcrumb: {label:"Digital Finance Application", to: "/forms/digital-finance-application"} },
      },     
      {
        path: "digital-finance-application/draft/:id",
        name: "digital-finance-application-draft",
        props: {isDraft:true},
        component: () => import('@/views/forms/Form.DigitalFinanceApplication/Form.DigitalFinanceApplication.vue'),
        meta: { layout: "blank-locale", breadcrumb: {label:"Digital Finance Application", to: "/forms/digital-finance-application/draft/:id"} },
      },
      {
        path: "digital-finance-application/view/:id",
        name: "digital-finance-application-view",
        props: {isReadOnly:true},
        component: () => import('@/views/forms/Form.DigitalFinanceApplication/Form.DigitalFinanceApplication.vue'),
        meta: { layout: "blank-locale", breadcrumb: {label:"Digital Finance Application", to: "/forms/digital-finance-application/view/:id"} },
      },
      //############## corpsec-reg-new-co ############## 
      {
        path: "public/corpsec-register-new-company",
        name: "public-corpsec-register-new-company",
        props: {isPublic:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCo/Form.CorpsecRegNewCo.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company"} },
      },
      {
        path: "public/corpsec-register-new-company/resume/:id",
        name: "corpsec-register-new-company-resume",
        props: {isPublic:true, isPublicSaved:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCo/Form.CorpsecRegNewCo.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/public/corpsec-register-new-company/resume/:id"} },
      },
      {
        path: 'corpsec-register-new-company',
        name: 'corpsec-register-new-company',
        component: () => import('@/views/forms/Form.CorpsecRegNewCo/Form.CorpsecRegNewCo.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company"} },
      },
      {
        path: "corpsec-register-new-company/draft/:id",
        name: "corpsec-register-new-company-draft",
        props: {isDraft:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCo/Form.CorpsecRegNewCo.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company/draft/:id"} },
      },
      {
        path: "corpsec-register-new-company/view/:id",
        name: "corpsec-register-new-company-view",
        props: {isReadOnly:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCo/Form.CorpsecRegNewCo.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company/view/:id"} },
      },      

      

      //############## corpsec-transfer-in ############## 
      {
        path: "public/corpsec-transfer-in-company",
        name: "public-corpsec-transfer-in-company",
        props: {isPublic:true},
        component: () => import('@/views/forms/Form.CorpsecTransferInCo/Form.CorpsecTransferInCo.vue'),
        meta: { breadcrumb: {label:"Transfer Company Secretary", to: "/forms/corpsec-transfer-in-company"} },
      },
      {
        path: "public/corpsec-transfer-in-company/resume/:id",
        name: "corpsec-transfer-in-company-resume",
        props: {isPublic:true, isPublicSaved:true},
        component: () => import('@/views/forms/Form.CorpsecTransferInCo/Form.CorpsecTransferInCo.vue'),
        meta: { breadcrumb: {label:"Transfer Company Secretary", to: "/forms/public/corpsec-transfer-in-company/resume/:id"} },
      },       
      {
        path: "corpsec-transfer-in-company",
        name: "corpsec-transfer-in-company",
        component: () => import('@/views/forms/Form.CorpsecTransferInCo/Form.CorpsecTransferInCo.vue'),
        meta: { breadcrumb: {label:"Transfer Company Secretary", to: "/forms/corpsec-transfer-in-company"} },
      },
      {
        path: "corpsec-transfer-in-company/draft/:id",
        name: "corpsec-transfer-in-company-draft",
        props: {isDraft:true},
        component: () => import('@/views/forms/Form.CorpsecTransferInCo/Form.CorpsecTransferInCo.vue'),
        meta: { breadcrumb: {label:"Transfer Company Secretary", to: "/forms/corpsec-transfer-in-company/draft/:id"} },
      },
      {
        path: "corpsec-transfer-in-company/view/:id",
        name: "corpsec-transfer-in-company-view",
        props: {isReadOnly:true},
        component: () => import('@/views/forms/Form.CorpsecTransferInCo/Form.CorpsecTransferInCo.vue'),
        meta: { breadcrumb: {label:"Transfer Company Secretary", to: "/forms/corpsec-transfer-in-company/view/:id"} },
      },          
      
      //############## corpsec-reg-new-co-combine ############## 
      {
        path: "public/corpsec-register-new-company-combine",
        name: "public-corpsec-register-new-company-combine",
        props: {isPublic:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombine/Form.CorpsecRegNewCoCombine.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine"}, layout: "blank-locale"},
      },
      {
        path: "public/corpsec-register-new-company-combine/resume/:id",
        name: "corpsec-register-new-company-resume-combine",
        props: {isPublic:true, isPublicSaved:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombine/Form.CorpsecRegNewCoCombine.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/public/corpsec-register-new-company-combine/resume/:id"}, layout: "blank-locale"},
      },      
      {
        path: 'corpsec-register-new-company-combine',
        name: 'corpsec-register-new-company-combine',
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombine/Form.CorpsecRegNewCoCombine.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine"}, layout: "blank-locale"},
      },
      {
        path: "corpsec-register-new-company-combine/draft/:id",
        name: "corpsec-register-new-company-combine-draft",
        props: {isDraft:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombine/Form.CorpsecRegNewCoCombine.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company/draft/:id"}, layout: "blank-locale"},
      },
      {
        path: "corpsec-register-new-company-combine/view/:id",
        name: "corpsec-register-new-company-combine-view",
        props: {isReadOnly:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombine/Form.CorpsecRegNewCoCombine.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine/view/:id"}, layout: "blank-locale"},
      },            

      //############## corpsec-reg-new-co-malaysia ############## 
      {
        path: "public/corpsec-register-new-company-combine-my",
        name: "public-corpsec-register-new-company-combine-my",
        props: {isPublic:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombineMY/Form.CorpsecRegNewCoCombineMY.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine-my"}},
      },
      {
        path: "public/corpsec-register-new-company-combine-my/resume/:id",
        name: "corpsec-register-new-company-resume-combine-my",
        props: {isPublic:true, isPublicSaved:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombineMY/Form.CorpsecRegNewCoCombineMY.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/public/corpsec-register-new-company-combine-my/resume/:id"}},
      },      
      {
        path: 'corpsec-register-new-company-combine-my',
        name: 'corpsec-register-new-company-combine-my',
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombineMY/Form.CorpsecRegNewCoCombineMY.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine-my"}},
      },
      {
        path: "corpsec-register-new-company-combine-my/draft/:id",
        name: "corpsec-register-new-company-combine-draft-my",
        props: {isDraft:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombineMY/Form.CorpsecRegNewCoCombineMY.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-my/draft/:id"}},
      },
      {
        path: "corpsec-register-new-company-combine-my/view/:id",
        name: "corpsec-register-new-company-combine-view-my",
        props: {isReadOnly:true},
        component: () => import('@/views/forms/Form.CorpsecRegNewCoCombineMY/Form.CorpsecRegNewCoCombineMY.vue'),
        meta: { breadcrumb: {label:"Register New Company", to: "/forms/corpsec-register-new-company-combine-my/view/:id"}},
      },            

    ]
  }, 
  // {
  //   path: "/intl-generate",
  //   name: "intl-generate",
  //   component: () => import("@/intl/Intl.vue"),
  //   meta: { layout: "blank" },
  // }, 
  {
    path: "/system-error/:errorType",
    name: "system-error",
    props: { errorType : null },
    component: () => import("@/views/Site.Error.vue"),
    meta: { layout: "blank" },
  },  
  {
    path: "/payment/success",
    name: "payment-success",
    props: true,
    component: () => import("@/views/Site.PaymentSuccess.vue"),
    meta: { layout: "blank" },
  },
  {
    path: "/:catchAll(.*)*",
    name: "bad-not-found",
    props: true,
    component: () => import("@/views/Site.NotFound.vue"),
    meta: { layout: "blank" },
  },
];


  // {
  //   path: '/corpsec/register-new-company',
  //   name: 'corpsec-register-new-company',
  //   component: () => import('@/views/form/Form.CorpsecRegNewCo.vue'),
  //   meta: { layout: "blank", requireAuth: false },
  // },  


const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      const pos = savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
      setTimeout(() => {
        // //console.log("savedPosition to ---->", to);
        resolve(pos);
      }, 200);
    });
  },
});

router.afterEach(async (to, from) => {
    
  if (to.meta.layout) {
    const storeSite = useStoreSite();
    // console.log("to.meta.layout", to.meta.layout)
    storeSite.setLayout(to.meta.layout);
  }
  const gtm = useGtm();
  if (to.path) {
    gtm.trackEvent({
      event: 'content-view',
      pageTitle: to.path === "/" ? "Central - Landing" : `Central - ${Utils.formatPath(to.path)}`,
      pageUrl: window.location.href
    })
  }
});


router.beforeEach(async (to, from, next) => {
  const storeSite = useStoreSite();
  const storeAccount = useStoreAccount();

  const init = storeSite.init;
  if (!init) {
    //console.log("init", init);
    storeSite.initialise();
    //storeSite.getApiVersion();

    const wasLoggedIn = storeSite.wasLoggedIn;
    if (wasLoggedIn) {
      const flag = await storeAccount.resumeLogin();
      //console.log("resume", flag);
      //const flag = false;
      if (flag?.status == 'success') {
        // showToast("Resumed login.", "success");
        if (to.name == "authenticate" && !to.query['redirect']) {
          //goto dashboard when try to sign in, when already logged in
          return next({ path: "/dashboard" });
        }
      }
    } 
  } 

  const isLoggedIn = storeAccount.isLoggedIn;
  if (to.meta.requireAuth) {
    //console.log("router to", to);
    if (!isLoggedIn) {
      // showToast("Please login.", "danger");
      return next({ path: "/" });
      // return next({path: '/', query:{ redirect:to.fullPath }});
    }
  }

  //from.meta?.scrollPos && (from.meta.scrollPos.top = window.scrollY);

  next();
});

export default router;













/*
{
  path: "/sign-in",
  name: "sign-in",
  component: () => import("@/views/Site.SignIn.vue"),
  meta: { layout: "landing" },
},
{
  path: "/sign-out",
  name: "sign-out",
  component: () => import("@/views/Site.SignOut.vue"),
  meta: { layout: "landing" },
},
{
  path: "/sign-in",
  name: "sign-in",
  // component: () => import('@/views/Site.SignIn.vue'),
  // meta: { layout: "landing" },
  beforeEnter(to, from, next) {
    ShareFunc.generatePKCE("https://account.meyzer360.com/auth?client_id=central&response_type=code&redirect_uri=https%3A%2F%2Fcentral.meyzer360.com%2Fauth-callback&code_challenge={code}&code_challenge_method=plain&scope=openid%20profile%20permissions").then(res=>{
      window.location.href = res
    }).catch(err=>{
      console.error(err)
    })
    // window.location.href =
    //   "https://account.meyzer360.com/auth?client_id=central&response_type=code&redirect_uri=https%3A%2F%2Fcentral.meyzer360.com%2Fauth-callback&code_challenge=6UDGlFa45ar4rcbTXiyYRxsKoxOAF2teahokS7qlDXY&code_challenge_method=plain&scope=openid%20profile%20permissions";
  },
},
*/