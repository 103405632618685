<script setup>
import {computed} from 'vue';

  const props = defineProps({
    size: {type:String},
    link: {type:String, default: "/"},
    isDisabled: {type:Boolean, default: false}
  })

  const logoStyle = computed(() => ({
    'logo-big' : props.size === 'big'
  }))
</script>

<template>
	<div class="logo-holder">
    <component
      :is="isDisabled ? 'span' : 'router-link'"
      :to="link"
    >
        <span>
          <i class="ico logo-m360 logo block" :class="logoStyle"></i>
        </span>
    </component>
	</div>
</template>

<style scoped lang="scss">
.logo-holder {
  .logo {
    width: 100px;
    height: 36.98px;

    &.logo-big {
      width: 140px;
      height: 51.778px;
    }
  }
}
</style>
